import React from "react"
import PropTypes from "prop-types"
import { Link, graphql, useStaticQuery } from "gatsby" // eslint-disable-line
import Logo from "components/logo"
import Nav from "components/nav"

const Header = () => {
  return (
    <header
      style={{
        marginBottom: `1.45rem`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1.45rem 1.0875rem`,
          textAlign: "center",
        }}
      >
        <Link
          to="/"
          style={{
            color: "black",
            textDecoration: `none`,
          }}
        >
          <Logo />
        </Link>
      </div>
      <Nav />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
