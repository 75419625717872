/** @jsx jsx */
import { Link as GLink } from "gatsby"
import { css, jsx } from "@emotion/core"

const Link = ({ to, label }) => (
  <GLink
    css={css`
      color: #a07b68 !important;
      display: inline-block;
      position: relative;
      text-decoration: none;
      padding: 1rem;

      &::before {
        content: "";
        position: absolute;
        width: calc(100% - 2rem);
        transform: scaleX(0);
        height: 2px;
        bottom: 1rem;
        left: 1rem;
        background-color: #a07b68;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }

      &:hover::before,
      &.c--active::before {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    `}
    activeClassName="c--active"
    to={to}
  >
    {label}
  </GLink>
)

const Nav = () => {
  return (
    <nav
      style={{
        maxWidth: "960px",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "1.5rem",
        background: "rgba(255,255,255,0.4)",
      }}
    >
      <Link to="/" label="Home" />
      <Link to="/about" label="About" />
      <Link to="/spoonie-living" label="Spoonie Living" />
      <Link to="/personal-passions" label="Personal Passions" />
      <Link to="/mrs-vancouver-island" label="Mrs. Vancouver Island" />
      <Link to="/contact" label="Contact" />
    </nav>
  )
}

export default Nav
